"use client";

// import { ThemeProvider as NextThemesProvider } from "next-themes"
import { ThemeProviderProps } from "next-themes";
import dynamic from "next/dynamic";

const NextThemesProvider = dynamic<ThemeProviderProps>(() => import("next-themes").then(mod => mod.ThemeProvider), {
  ssr: false,
});

const ThemeProvider = ({ children, ...props }: ThemeProviderProps) => {
  return <NextThemesProvider {...props}> {children} </NextThemesProvider>;
};
export default ThemeProvider;
