"use client";

import { wagmiAdapter, projectId, networks } from "@/wagmi/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createAppKit } from "@reown/appkit/react";
import { ReactNode } from "react";
import { cookieToInitialState, WagmiProvider, type Config } from "wagmi";
import { AppKitNetwork, bsc, bscTestnet } from "@reown/appkit/networks";

if (!projectId) {
  throw new Error(
    "projectId is not defined - please set it in wagmi/context/index.ts"
  );
}

const queryClient = new QueryClient();

const metadata = {
  name: "Intelliverse ICPTG",
  description:
    "A suite of WEB3 tools based on AI technology, allow to launch and grow blockchain-powered projects efficiently.",
  image: "https://intelliverse-ai.com/public/logo/LogoTransparentBlack.png",
  url: "https://intelliverse-ai.com/website",
  twitter: "https://twitter.com/ai_icptg",
  icons: ["https://intelliverse-ai.com/logo/Logo460x460.png"],
};

createAppKit({
  adapters: [wagmiAdapter],
  projectId,
  allowUnsupportedChain: false,
  metadata,
  networks: networks as [AppKitNetwork, ...AppKitNetwork[]],
  defaultNetwork: process.env.NODE_ENV === "development" ? bscTestnet : bsc,
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
  features: {
    analytics: true,
    email: false,
    socials: [],
    swaps: false,
    onramp: false,
  },
});

const ContextProvider = ({
  children,
  cookies,
}: {
  children: ReactNode;
  cookies: string | null;
}) => {
  const initialState = cookieToInitialState(
    wagmiAdapter.wagmiConfig as Config,
    cookies
  );
  return (
    <WagmiProvider
      config={wagmiAdapter.wagmiConfig as Config}
      initialState={initialState}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
};

export default ContextProvider;
