import * as z from "zod";
import { TokenFormSchema } from "@/utils/ZodSchema/createTokenSchema";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type TCreateTokenSchemaOriginal = z.infer<typeof TokenFormSchema>;
type TCreateTokenSchema = Omit<TCreateTokenSchemaOriginal, "initialSupply"> & {
  initialSupply: string;
};
const createTokenInitialState = {
  tokenName: "",
  tokenSymbol: "",
  tokenDecimals: 18,
  initialSupply: "",
  description: "",
  ownerAddress: "",
  chainSelected: "",
  isBurnable: false,
  isMintable: false,
  isTaxable: false
} as unknown as TCreateTokenSchema;

export const createTokenSlice = createSlice({
  name: "createToken",
  initialState: createTokenInitialState,
  reducers: {
    setTokenName: (state, action: PayloadAction<string>) => {
      state.tokenName = action.payload;
    },
    setTokenSymbol: (state, action: PayloadAction<string>) => {
      state.tokenSymbol = action.payload;
    },
    setTokenDecimals: (state, action: PayloadAction<number>) => {
      state.tokenDecimals = action.payload;
    },
    setInitialSupply: (state, action: PayloadAction<string>) => {
      state.initialSupply = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setOwnerAddress: (state, action: PayloadAction<string>) => {
      state.ownerAddress = action.payload;
    },
    setChainSelected: (state, action) => {
      state.chainSelected = action.payload;
    },
    setIsBurnable: (state, action: PayloadAction<boolean>) => {
      state.isBurnable = action.payload;
    },
    setIsMintable: (state, action: PayloadAction<boolean>) => {
      state.isMintable = action.payload;
    },
    setIsTaxable: (state, action: PayloadAction<boolean>) => {
      state.isTaxable = action.payload;
    },
  },
});

export const selectTokenName = (state: RootState) =>
  state.createToken.tokenName;
export const selectTokenSymbol = (state: RootState) =>
  state.createToken.tokenSymbol;
export const selectTokenDecimals = (state: RootState) =>
  state.createToken.tokenDecimals;
export const selectInitialSupply = (state: RootState) =>
  state.createToken.initialSupply;
export const selectDescription = (state: RootState) =>
  state.createToken.description;
export const selectOwnerAddress = (state: RootState) =>
  state.createToken.ownerAddress;
export const selectChainSelected = (state: RootState) =>
  state.createToken.chainSelected;
export const selectIsBurnable = (state: RootState) =>
  state.createToken.isBurnable;
export const selectIsMintable = (state: RootState) =>
  state.createToken.isMintable;
export const selectIsTaxable = (state: RootState) => state.createToken.isTaxable

export const {
  setTokenName,
  setTokenSymbol,
  setTokenDecimals,
  setInitialSupply,
  setDescription,
  setOwnerAddress,
  setChainSelected,
  setIsBurnable,
  setIsMintable,
  setIsTaxable
} = createTokenSlice.actions;

export default createTokenSlice.reducer;
