import { cookieStorage, createStorage, http } from "@wagmi/core";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import {
  mainnet,
  bsc,
  bscTestnet,
  sepolia,
  AppKitNetwork,
} from "@reown/appkit/networks";

export const projectId = process.env.NEXT_PUBLIC_PROJECT_ID || "";

if (!projectId) {
  throw new Error("NEXT_PUBLIC_PROJECT_ID is required");
}

export const networks: AppKitNetwork[] =
  process.env.NODE_ENV === "development"
    ? [mainnet, bsc, bscTestnet, sepolia]
    : [bsc, bscTestnet];

export const wagmiAdapter = new WagmiAdapter({
  storage: createStorage({
    storage: cookieStorage,
  }),
  ssr: true,
  projectId,
  networks,
});
