import(/* webpackMode: "eager" */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/shared/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/wagmi/context/index.tsx");
