import { configureStore } from "@reduxjs/toolkit";
import { stepperSlice } from "./slices/stepperSlice";
import { createTokenSlice } from "./slices/createTokenSlice";
import { privateSaleSlice } from "./slices/website/privateSaleSlice";

export const makeStore = () => {
  return configureStore({
    reducer: {
      stepper: stepperSlice.reducer,
      createToken: createTokenSlice.reducer,
      privateSale: privateSaleSlice.reducer,
    },
  });
};

// infer the type of the makeStore
export type AppStore = ReturnType<typeof makeStore>;
// infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
