import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface StepperState {
  activeStep: number;
  createTokenStepsNumber: number;
}

const initialState = {
  activeStep: 1,
  createTokenStepsNumber: 5,
} as StepperState

export const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    incrementStep: (state) => {
      state.activeStep += 1;
    },
    decrementStep: (state) => {
      state.activeStep > 1 ? (state.activeStep -= 1) : state.activeStep;
    },
    setActiveStep : (state, action : PayloadAction<number>) => {
        action.payload > 0 ? state.activeStep = action.payload : state.activeStep
    },
  },
});

export const selectActiveStep = (state: RootState) => state.stepper.activeStep
export const selectCreateTokenStepsNumber = (state: RootState) => state.stepper.createTokenStepsNumber
export const {
  incrementStep,
  decrementStep,
  setActiveStep
} = stepperSlice.actions;

export default stepperSlice.reducer;
